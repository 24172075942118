import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "Trendy",
  sku: "Sk95",
  availability: "In stock",
  title: "OCSO Trendy Men's Shandow Sk95",
  mrpPrice: "1099",
  sellPrice: "449",
  disc:
    "A uber-cool approach to give your regular exercise wear a reviving update, is this red and black tank from ocso. that gloats of a lively tint and even stripes everywhere. Produced using a mix of 95% polyester and 5% spandex, this tank will be stretchable just as exceptionally agreeable to wear. It has a round neck and differentiating brand logo on the front that loan a tasteful look. This tank can be collaborated with a couple of blue joggers and dark tennis shoes to put your best self forward.",
};
export default () => {
  return <ProdLayout data={data} />;
};
